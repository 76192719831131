<template>
  <div>
    <div class="we" v-for="item in tableData" :key="item.id">
      <div class="xd">
        <div style="padding-top:20px">
          <div class="lk">{{item.level_name}}</div>
          <div class="dc">{{item.year_price.toFixed(2)}}/年</div>
        </div>
        <div style="display: flex; margin: 10px auto; width: 38%;">
          <el-button
            :disabled="item.is_level !== 0 || level_duetimeData < 1"
            class="df"
            type="primary"
            size="small"
            @click="beforeOpenUpDialog(item)"
          >
            <!--  -->
            {{item.is_level===0?"我要升级":"当前等级"}}
          </el-button>
          <el-button v-if="item.is_level === 1" type="primary" size="small" class="df" @click="openDialog(item)">
            我要续费
          </el-button>
        </div>
      </div>
      <div class="xdd">
        <div class="ml tablea">
          <span style="text-align:left">等级描述</span>
          <span style="text-align:right">{{item.level_dec}}</span>
        </div>
        <div class="ml tablea">
          <span style="text-align:left">拥有商城数量</span>
          <span style="text-align:right">{{item.store_num===-1?"不限":item.store_num}}</span>
        </div>
        <div class="ml tablea">
          <span style="text-align:left">拥有套餐卡商城数量</span>
          <span style="text-align:right">{{item.package_store_num===-1?"不限":item.package_store_num}}</span>
        </div>
<!--        <div class="ml tablea">-->
<!--          <span style="text-align:left">可制套餐卡型号数量</span>-->
<!--          <span style="text-align:right">{{item.package_num===-1?"不限":item.package_num}}</span>-->
<!--        </div>-->
        <div class="ml tablea">
          <span style="text-align:left">可添加员工账户数量</span>
          <span style="text-align:right">{{item.staff_num===-1?"不限":item.staff_num}}</span>
        </div>
        <div class="ml tablea">
          <span style="text-align:left">是否有供应商应用</span>
          <span style="text-align:right">{{item.has_supplier===1?"是":"否"}}</span>
        </div>
        <div class="ml tablea">
          <span style="text-align:left">是否有套餐卡商城应用</span>
          <span style="text-align:right">{{item.has_package_store===1?"是":"否"}}</span>
        </div>
        <div class="ml tablea">
          <span style="text-align:left">是否有方案报价应用</span>
          <span style="text-align:right">{{item.has_programme===1?"是":"否"}}</span>
        </div>
        <div class="ml tablea">
          <span style="text-align:left">付费定制开发优惠</span>
          <span
            style="text-align:right"
          >{{item.customized_discount!==0?item.customized_discount+"折":"无优惠"}}</span>
        </div>

        <div class="ml tablea">
          <span style="text-align:left; margin-right: 8px">自有商品订单服务费</span>
          <span style="text-align:right" v-if="item.self_goods_price.priceType===0">不限</span>
          <span style="text-align:right" v-else>
            <el-button
              @click="handlebtnClick(item.self_goods_price.orderPriceList)"
              size="mini"
              type="primary"
            >查看</el-button>
          </span>
        </div>
        <!-- <div class="ml tablea">
          <span style="text-align:left">其他服务</span>
          <span style="text-align:right">{{item.other}}</span>
        </div> -->
      </div>
    </div>

    <el-dialog title="自有商品订单服务费" :visible.sync="centerDialogVisible" width="30%">
      <table border width="100%">
        <tr height="60">
          <th width="100">单数</th>
          <th
            v-for="(itm1,idx) in dialogTableData"
            :key="idx"
            style="text-align: center;"
          >{{itm1.orderNum}}</th>
        </tr>
        <tr height="60">
          <td style="text-align: center;">费用</td>
          <td
            v-for="(itm2,index) in dialogTableData"
            :key="index"
            style="text-align: center;"
          >{{itm2.price}}</td>
        </tr>
      </table>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="centerDialogVisible = false" size="small">取 消</el-button> -->
        <el-button type="primary" @click="centerDialogVisible = false" size="small">确 定</el-button>
      </span>
    </el-dialog>

<!--    续费-->
    <x-dialog :proxy="renewDialogProxy">
      <el-form :model="renewInfo" label-width="140px">
        <el-form-item label="当前企业等级">
          {{ renewInfo.level_name }}
        </el-form-item>
        <el-form-item label="当前企业剩余天数">
          {{ renewInfo.surplus_day }}天
        </el-form-item>
        <el-form-item label="续费年限选择">
          <div class="renew-select" v-if="renewInfo.renewals_setting_vo" style="display: flex; justify-content: space-between; width: 95%; margin-left: 2%;">
            <template v-for="(item, index) in renewInfo.renewals_setting_vo.year_discount_volist">
              <div class="select-item" :key="index">
                <h3>{{ item.renewal_period}}年</h3>
                <div class="price" :class="selectIndex === item.renewal_period ? 'active' : ''" v-if="item.discount === 10" @click="selectRenewYear(item)">
                  <span>¥{{ item.discount_price }}元</span>
                </div>
                <div class="price" :class="selectIndex === item.renewal_period ? 'active' : ''" v-else @click="selectRenewYear(item)">
                  <p>{{ item.discount }}折</p>
                  <div>
                    <span>折扣价：</span>
                    <span>¥{{ parseInt(item.discount_price) }}元</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </el-form-item>
      </el-form>
    </x-dialog>
<!--    确认支付-->
    <x-dialog :proxy="upDialogProxy">
      <template>
        <div class="row1">
          企业等级:
          <span>{{ pay_item.level_name }}</span>
        </div>
        <div class="row1">
          账户余额:
          <span>{{ pay_item.moreNum }}</span>
        </div>
        <div class="row1">
          支付:
          <span>{{ pay_item.year_price | unitPrice("￥") }}</span>
        </div>
      </template>
    </x-dialog>
  </div>
</template>

<script>
import * as API_SiteMenu from "@/api/siteMenu";
import * as API_Account from "@/api/account";
import xDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import * as API_shop from "@/api/shop";

export default {
  name: "goodsAudit",
  components: {
    xDialog
  },
  data() {
    return {
      tableData: {},
      // 弹出框表格数据
      dialogTableData: [],
      // 自有商品订单服务费框
      centerDialogVisible: false,
      // 创建服务订单接口数据
      params: {
        service_type: 2,
        aim_id: 0
      },
      // 确认支付接口数据
      order_params: {
        payment_plugin_id: "shopBalancePlugin",
        pay_mode: "normal",
        client_type: "PC",
        trade_type: "service"
      },
      // 创建服务订单接口返回的订单号
      sn: "",
      // 确认支付框
      dialogVisibleg: false,
      pay_item: {},
      renewDialogProxy: $xDialog.create({
        title: '续费操作',
        wrapperClass: 'renew-wrapper-class',
        width: '50vw',
        beforeConfirm: () => this.renewConfirm(),
        beforeCancel: () => this.renewDialogProxy.dismiss()
      }),
      renewInfo: {
        level_name: '',
        year_price: '',
        renewals_setting_vo: ''
      },
      selectIndex: 1,
      currentEnterpriseLevel: '',
      currentYearPrice: '',
      upDialogProxy: $xDialog.create({
        title: '确认支付',
        width: '30vw',
        beforeCancel: () => this.upDialogProxy.dismiss(),
        beforeConfirm: () => this.addLv()
      }),
      // 剩余天数
      level_duetimeData: ''
    };
  },
  mounted() {
    this.GET_List();
  },
  activated() {
    this.GET_List();
  },
  methods: {
    // 获取剩余天数
    getSurplusDay() {
      API_shop.sellerShops().then(response => {
        let date1 = new Date(); //开始时间
        let date3 = response.level_duetime * 1000 - date1.getTime(); //时间差的毫秒数zhi
        this.level_duetimeData = Math.floor(date3 / (24 * 3600 * 1000));
      });
    },
    //续费弹窗
    openDialog(item) {
      this.currentEnterpriseLevel = item.id
      API_SiteMenu.getRenewInfo().then(res => {
        this.renewInfo = res
        const date1 = new Date(); //开始时间
        const date3 = res.surplus_day * 1000 - date1.getTime(); //剩余的过期时间
        res.surplus_day = Math.floor(date3 / (24 * 3600 * 1000)) > 0 ? Math.floor(date3 / (24 * 3600 * 1000)) : 0;
      })
      this.renewDialogProxy.display()
    },
    // 选择续费年限
    selectRenewYear(item) {
      this.selectIndex = item.renewal_period
    },
    // 确认续费
    renewConfirm() {
      let _param = {
        aim_id: this.currentEnterpriseLevel,
        service_type: 6,
        ...this.renewInfo.renewals_setting_vo.year_discount_volist[this.selectIndex - 1]
      }
      console.log(_param)
      API_Account.createOrder(_param).then(res => {
        this.sn = res
        API_Account.pay(this.order_params, this.sn).then(res => {
          let callBackParams = {
            pay_order_no: res.pay_order_no,
            need_pay_money: res.need_pay_money,
            out_trade_no: res.out_trade_no,
            renewal_period: this.selectIndex
          };
          API_Account.callBack(res.call_back_url, callBackParams).then(resp => {
            this.renewDialogProxy.dismiss();
            API_SiteMenu.getRenewInfo().then(res => {
              const date1 = new Date(); //开始时间
              const date3 = res.surplus_day * 1000 - date1.getTime(); //剩余的过期时间
              res.surplus_day = Math.floor(date3 / (24 * 3600 * 1000)) > 0 ? Math.floor(date3 / (24 * 3600 * 1000)) : 0;
              this.$message.success(`企业续费成功，当前企业剩余天数：${res.surplus_day}`)
            })
          });
        })
      })
    },
    // 支付
    addLv() {
      API_Account.pay(this.order_params, this.sn).then(res => {
        console.log(res, "this.order_params");
        let callBackParams = {
          pay_order_no: res.pay_order_no,
          need_pay_money: res.need_pay_money,
          out_trade_no: res.out_trade_no
        };
        API_Account.callBack(res.call_back_url, callBackParams).then(resp => {
          this.dialogVisibleg = false;
          this.$message.success("支付成功");
          this.GET_List();
        });
      });
    },
    beforeOpenUpDialog(item) {
      if (this.currentYearPrice > item.year_price) {
        this.$confirm('要升级到的企业等级可能比当前企业等级低，存在企业权益降低的风险。请确认无误后再操作！', '操作提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.up(item)
        })
      } else {
        this.up(item)
      }
    },
    // 我要升级
    up(item) {
      this.params.aim_id = item.id;
      API_Account.getShopUpInfo({'year_price': item.year_price}).then(res => {
        this.pay_item.moreNum = res.balance_account;
        this.pay_item.year_price = res.up_price
        this.params.price = res.up_price
      }).then(() => {
        API_Account.createOrder(this.params).then(res => {
          this.sn = res;
          this.pay_item = {
            ...item,
            ...this.pay_item
          };
          this.upDialogProxy.display()
        })
      })
    },
    // 查看自有商品订单服务费表格
    handlebtnClick(item) {
      console.log(item);
      this.dialogTableData = item;
      this.centerDialogVisible = true;
    },
    GET_List() {
      API_SiteMenu.getAllList().then(res => {
        res.forEach(e => {
          e.self_goods_price = JSON.parse(e.self_goods_price);
        });
        res.forEach(item => {
          if (item.is_level === 1) {
            this.currentYearPrice = item.year_price
          }
        })
        this.tableData = res;
      });
      this.getSurplusDay()
    }
  }
};
</script>
<style lang="scss" scoped>
.ml {
  background-color: white;
  line-height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  justify-content: space-between;
  color: #333333;

}

.renew-wrapper-class {
  .renew-select {
    .select-item {
      text-align: center;
      width: 140px;
      .price {
        color: red;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        cursor: pointer;
        p {
          margin-bottom: 0;
          height: 20px;
          line-height: 24px;
          color: #cdcdcd;
        }
        div {
          height: 25px;
          line-height: 25px;
          span:first-child {
            color: #cdcdcd;
          }
        }
      }
      .active {
        color: white;
        background-color: #409eff;
        p {
          color: white;
        }
        div {
          span:first-child {
            color: white;
          }
        }
      }
    }
  }
}
.ml:last-child {
  border: none;
}
//.ml:nth-child(2n) {
//  background-color: white;
//}
.row1 {
  padding-left: 50px;
  font-size: 16px;
  margin-bottom: 15px;
}
.xdd {
  overflow-y: auto;
  padding-bottom: 24px;
}
.df {
  display: block;
  margin: 0 auto;
  margin-top: 10px;
}
.dc {
  text-align: center;
  color: #FFFFFF;
  // margin-top: 10px;
}
.we {
  width: 30%;
  margin-right: 20px;
  display: inline-block;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 30px;
}
.we:nth-child(3n) {
  margin-right: 0;
}
.xd {
  // background-color: rgb(255, 177, 0);
  height: 225px;
  border-radius: 30px;
  background-image: url("../../assets/enterprise_leval.png");
  background-position: center;
  background-size: 125% 139%;
}
.lk {
  text-align: center;
  overflow: hidden;
  font-size: 15px;
  margin: auto;
  margin-top: 47px;
  color: #FFFFFF;
}
</style>
